import { useStaticQuery, graphql } from "gatsby"

const ALL_DISCOUNT_COUPONS = graphql`
  query DiscountCoupons {
    allDiscountCoupons {
      nodes {
        activation
        alternatingQuantity
        code
        created
        company
        discountType
        percentOff
        id
        includeConvenienceFee
        key
        notes
        productInclusions {
            value
            type
        }
        productExclusions {
            value
            type
          }
        websiteDescription
      }
    }
  }
`

export const useDiscountCoupons = () => {
  const data = useStaticQuery(ALL_DISCOUNT_COUPONS)

  return data
}
