import React, { useRef, useState, useEffect, useContext } from "react"

import Dropzone from "./Dropzone"
import CartLayout from "../Layout/CartLayout"

import EpharmacyCart from "../Epharmacy/Cart"
import beneficiaries from "../../components/PersonalDetails/utils/beneficiaryTypes.json"
import ConfirmNavigationModal from "../Elements/ConfirmNavigationModal"
import UploadGuidelines from "./UploadGuidelines"
import { useDiscountCoupons } from "../Epharmacy/hooks/useDiscountCoupons"
import { getContextFromSession } from "context/services/context"

import {beneficiaryValidationSchema} from "components/Epharmacy/utils/formData.js"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"




import { AppContext } from "../../context/AppContext"
import { Formik, Form } from "formik"

import FormInput from "elements/Form/FormInput"
import FormSelect from "elements/Form/FormSelect"

import { navigate } from "gatsby"
import { isObjectEmpty } from "../../services/general"


const MAX_FILE_SIZE_IN_BYTES = 3145728
const MAX_FILE_COUNT = 6

const Upload = ({ backRoute, nextRoute, flow }) => {
  const { dispatch, state } = useContext(AppContext)
  const [filesUploaded, setFilesUploaded] = useState(state.documents)
  const [isOthers,setIsOthers] = useState(false)
  let formValues = getContextFromSession()
  if (isObjectEmpty(formValues)) formValues = state

  const Cart = (
      <EpharmacyCart open={{ documentsCard: true }} hidden = {{documents:false , medicines: true}}/>
    )
  let coupons = useDiscountCoupons()
  coupons = coupons?.allDiscountCoupons?.nodes

  const displayNavigationModal = () => {
    dispatch({
      type: "SHOW_MODAL",
      payload: {
        heading: "Cancel your request",
        isCard: true,
        headerClass: "is-size-5",
        content: <ConfirmNavigationModal backRoute={backRoute} />,
      },
    })
  }

  const noDocuments = state.documents.length === 0


  const beneficiaryInitialValues = {
    beneficiaryName: "",
    beneficiaryType: {relationship:"",type:""}
  }

  const handleOnChange = (values) => {
    if(values.value === 'others'){
      setIsOthers(true)
    } else setIsOthers(false)
  }

  const handleSubmit = async (values) => {
    let coupon = coupons.find(
      coupon => coupon.code.includes(values.beneficiaryType.type)
      )
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { 
          ...state.epharmacy, ...values, coupon},
      }
    })
    navigate(nextRoute)
  }

  useEffect(() => {
    if (state.documents.length < filesUploaded.length)
      setFilesUploaded(state.documents)
    
  }, [state])

  useEffect(() => {
    dispatch({ type: "GET_CONTEXT_FROM_SESSION" })
    if(state?.epharmacy.beneficiaryType.value === "others"){
      setIsOthers(true)
    }
  }, [])

  return (
    <Formik
      initialValues={ formValues.epharmacy }
      onSubmit={handleSubmit}
      validationSchema={beneficiaryValidationSchema}
    >
    {({ values }) => (
      <Form autoComplete="off">
        <CartLayout
          isCentered
          desktop={8}
          fullhd={8}
          submit={{
            label: "Next",
            disabled: noDocuments,
          }}
          back={{
            label: "Back",
            link: backRoute,
          }}
          cartContainer={Cart}
        > 
          <UploadGuidelines />
          <Dropzone
            maxByteSize = {MAX_FILE_SIZE_IN_BYTES}
            maxFileCount = {MAX_FILE_COUNT}
            docType = "RX"
          />
          <div className = "message is-light is-size-6 mt-3">
            <div className = "message-body">
              <span className="icon has-text-primary">
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </span>
              Your request will be validated based on the name on the prescription. Only registered employees and dependents as provided by Viatris will be eligible for this benefit.
            </div>
          </div>
          <FormInput
            name="beneficiaryFirstName"
            placeholder="Juana"
            value={values.beneficiaryFirstName}
            maxLength = {50}
            label="First Name in Prescription"
            isRequired
          /> 
          <FormInput
            name="beneficiaryLastName"
            placeholder="Dela Cruz"
            value={values.beneficiaryLastName}
            maxLength = {50}
            label="Last Name in Prescription"
            isRequired
          />
          <FormSelect
            name="beneficiaryType"
            placeholder="Self"
            value={values.beneficiaryType}
            label="Relationship to Employee"
            helper = "Beneficiary is your:"
            options = {beneficiaries}
            onChange = {handleOnChange}
            isRequired
          />
          {isOthers && 
            <FormInput 
              name = "beneficiaryOthers"
              placeholder="Cousin"
              value={values.beneficiaryOthers}
              label = "Please Specify Relationship to Employee"
              isRequired = {isOthers}
            />
          }
        </CartLayout>
      </Form>
                    )}
    </Formik>
  )
}

export default Upload
